
//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";

//
// inverse
// --------------------------------------------------
.inverse{
  color: #fff;
  a:not(.btn){
    color: lighten(#ddd,10%);
    &:hover,
    &:focus{
      color: @brand-primary;
    }
  }
  hr{
    border-top: 1px solid #fff;
  }
}

footer.inverse{
  color: #ddd;
  a:not(.btn){
    color: lighten(#ddd,10%);
    &:hover,
    &:focus{
      color: @brand-primary;
    }
  }
}

//
// button
// --------------------------------------------------
.btn-custom01{
  display: inline-block;
  padding:15px 20px;
  border: 5px solid #fff;
  color: #333;
  position: relative;
  .transition(.4s);
  >span{
    color: @brand-info;
    font-size: @font-size-smaller;
    margin-left:1em;
  }
  &:hover{
    text-decoration: none;
    color: #333;
    opacity: 0.8;
  }
  >i.fa{
    color: #fff;
    background: @brand-primary;
    display: inline-block;
    padding:0px 5px;
    position: absolute;
    right: -5px;
    top: 1.3em;
  }
}

.btn-custom02{
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  .transition(.4s);
  >img{
    border:5px solid #fff;
  }
  &:hover{
    text-decoration: none;
    opacity: 0.8;
  }
  i.fa{
    color: #fff;
    background: @brand-primary;
    display: inline-block;
    padding:0px 5px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}

//
// anchor
// --------------------------------------------------
.anchor{
  margin-top: -100px;
  padding-top: 100px;
  display: block;
}

//
// breadcrumb
// --------------------------------------------------
.breadcrumb {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: inline-block;
  margin-bottom: 0px;
  border-radius: 0px;
  font-size: 11px;
  background: rgba(255, 255, 255, 0.7);
  background: #fff\9;
  z-index: 100;
}

//
// table
// --------------------------------------------------
.table{
  font-size: @font-size-small;
  border-bottom:1px solid @table-border-color;
  &.table-bordered{
    th{
      background-color: @table-bg-active;
    }
    td{
      background-color: #fff;
    }
  }
  th{
    label{
      font-size: 11px;
      font-weight: normal;
      margin-top: 2px;
    }
  }
}


//
// tos
// --------------------------------------------------
.tos{
  height: 8em;
  text-align: left;
  overflow: auto;
}

//
// well
// --------------------------------------------------
.well{
  background: rgba(255,255,255,0.8);
  border:0px;
  box-shadow: 0px;
}

//
// panel
// --------------------------------------------------
.panel{
  border:0px;
  box-shadow: none;
  .panel-heading,
  .panel-footer{
    border: 0px;
  }
}

//
// font size
// --------------------------------------------------
.font-size-large{
  font-size: @font-size-large;
}

//
// text-color
// --------------------------------------------------
.brand-primary{
  color: @brand-primary;
}
.brand-info{
  color: @brand-info;
}

//
// bg-type
// --------------------------------------------------
.bg-light-blue{
   background-color: #eaf0f3;
 }

.bg-light-blue02{
  background-color: #b1f5fe;
}

.bg-light-blue03{
  background-color: #f4f9fa;
}

.bg-light-blue04{
  background-color: #c5dffa;
}
.bg-light-blue05{
  background-color: #d4eaf1;
}

.bg-blue{
  background-color: #0b78b4;
}

.bg-blue02{
  background-color: #00a2ff;
}

.bg-light-green{
  background-color: #e8f0e9;
}

.bg-light-green02{
  background-color: #eaf7af;
}
.bg-light-green03{
  background-color: #f4faf5;
}

.bg-light-brown{
  background-color: #dddcd5;
}

.bg-light-brown02{
  background-color: #ece5e1;
}

.bg-light-brown03{
  background-color: #d6cfc9;
}

.bg-light-gray{
  background-color: #f3f3f3;
}

.bg-light-gray02{
  background-color: #f6f6f8;
}
.bg-light-gray03{
  background-color: #f3f1f1;
}

.bg-light-pink{
  background-color: #f7f3f4;
}

.bg-light-pink02{
  background-color: #ffe5f2;
}

.bg-brown{
  background-color: #504e44;
}

.bg-red{
  background-color: #e20040;
}

.bg-inner-white{
  background-color: rgba(255,255,255,0.7);
}
.bg-inner-blue{
  background-color: rgba(11,67,88,0.7);
}

//
// tab
// --------------------------------------------------
.nav-tabs{
  li{
    a{
      background: rgba(255,255,255,0.5);
      .transition(.4s);
      &:hover,
      &:focus{
        background: rgba(255,255,255,0);
      }
    }
    &.active{
      a{
        background: inherit!important;
        border-bottom-color: transparent!important;
      }
    }
  }
}

//
// wyswyg
// --------------------------------------------------
.alignright{
  float: right;
  margin-left:15px;
  margin-bottom:15px;
}

.alignleft{
  float:left;
  margin-left:15px;
  margin-bottom:15px;
}

//
// type
// --------------------------------------------------
.deco{
  > span{
    display: inline-block;
    color: #333;
    font-style: italic;
    font-size: small;
    padding-left: 1em;
  }
  &.horizontal{
    > span {
      padding-left: 0em;
      padding-top:0.5em;
      display: block;
    }
  }
}

//
// input size
// --------------------------------------------------
.input-size-xxs{width:15%}
.input-size-xs{width:30%}
.input-size-s{width:45%}
.input-size-m{width:60%}
.input-size-l{width:75%}
.input-size-xl{width:90%}
.input-size-xxl{width:100%}

//
// navbar
// --------------------------------------------------
.navbar-default{
  background-color: transparent;
  border-bottom: 0px;
  .navbar-header-bg{
    background-color: #fff;
    border-bottom: 1px solid #eee;
    .navbar-brand{
      padding:9px 15px;
      .transition(.4s);
      img{
        .transition(.4s);
        width: auto;
        height: 100%;
      }
    }
    .navbar-contact{
      float:right;
      margin-bottom: 0px;
      margin-right:0px;
      margin-top: 12px;
      .transition(.4s);
      li{
        display: inline-block;
        &.phone-text{
          margin-right:10px;
          font-size: @font-size-small;
          text-align: right;
          line-height: 120%;
          small{
            font-size: @font-size-smaller;
          }
        }
        &.phone-number{
          position: relative;
          top:-3px;
          margin-right: 10px;
          font-size: 25px;
          font-weight: bold;
          img{
            margin-top: -0.3em;
          }
        }
        &.contact-header{
          position: relative;
          top:-7px;
        }
      }
    }
  }
  .navbar-bg02{
    background-color: rgba(255,255,255,0.8);
    .navbar-nav{
      width: 100%;
      @media screen and (max-width: @screen-xs-max) {
        border-right:0px!important;
        margin:0px!important;
      }
      >li{
        display: block;
        width:16.66%;
        text-align: center;
        border-left: 1px solid #eee;
        position: static;
        @media screen and (max-width: @screen-xs-max) {
          width:auto!important;
          text-align: left;
          border-left:0px!important;
          border-right:0px!important;
          a{
            padding-top: 12px!important;
            padding-bottom: 12px!important;
          }
        }
        a{
          width: 100%;
          font-weight: bold;
          color: #333;
          padding-top: 20px;
          padding-bottom: 20px;
          .transition(.4s);
        }
        &.active,
        &:hover,
        &:focus{
          >a{
            background-color: #fff!important;
            color: @brand-info;
          }
        }
      }
      &:last-child{
        border-right: 1px solid #eee;
      }
    }
  }
  .dropdown-menu{
    border:0px;
    width: 100%;
    box-shadow: none;
    text-align: center;
    @media screen and (max-width: @screen-xs-max) {
      display: none;
    }
    li{
      display: inline-block;
      text-align: center;
      a{
        font-size: @font-size-small;
        width: 100%;
        padding:10px 20px!important;
        font-weight: normal;
      }
      &:last-child{
        border-bottom:0px;
      }
    }
  }
  .dropdown{
    &:hover .dropdown-menu,
    &.active .dropdown-menu{
    }
    &.active .dropdown-menu{
      z-index: 999;
      display: block;
    }
  }
}

body.navbar-thin{
  .navbar-header-bg{
    .navbar-brand{
      height: 35px;
      padding:3px 15px 0px 15px;
      margin-top:5px;
      img{
        height: 100%;
        width: auto;
      }
    }
    .navbar-contact{
      margin-top:5px;
    }
  }
  .navbar-bg02{
    .navbar-nav{
      >li{
        >a{
          padding-top: 8px;
          padding-bottom: 8px;
        }
        .dropdown-menu{
          >li{
            >a{
              padding:0px 20px!important;
              .transition(.4s);
            }
          }
        }
      }
    }
  }
}

//
// local nav
// --------------------------------------------------
.local-nav{
  font-size: small;
  a{
    color: #333!important;
    font-weight: bold;
    display: inline-block;
    padding: 10px 20px;
    .transition(.2s);
    &:hover,
    &:focus{
      background-color: @nav-link-hover-bg;
      text-decoration: none;
    }
  }
}

//
// topback
// --------------------------------------------------
.topback{
  a{
    display: block;
    text-align: center;
    width:50px;
    padding:8px 8px;
    background: @brand-primary;
    color: #fff;
    position: fixed;
    right:10px;
    bottom:10px;
    &:before{
      margin:0px;
      padding:0px;
    }
  }
}

//
// pages
// --------------------------------------------------
body.pages{
  .first-content{
    padding-top: 250px;
    @media screen and (max-width: @screen-xs-max) {
      padding-top: 80px;
    }
    &.thin{
      padding-top: 200px;
      @media screen and (max-width: @screen-xs-max) {
        padding-top: 80px;
      }
    }
    &.thinner{
      padding-top: 160px;
      @media screen and (max-width: @screen-xs-max) {
        padding-top: 80px;
      }
    }
  }
}

//
// contact-base
// --------------------------------------------------
.contact-base{
  li{
    padding-top:15px;
    padding-bottom:15px;
    padding-right:30px;
  }
  li:first-child{
    line-height: 110%;
    border-right: 1px solid #ccc;
    @media screen and (max-width: @screen-xs-max) {
      border-right: 0px;
      margin-top: 1em;
    }
    img{
      position: relative;
      top:-6px;
    }
    strong{
      font-size: 25px;
    }
    span{
      display: block;
      font-size: smaller;
    }
  }
  li:last-child{
    position: relative;
    top: -12px;
    padding-left:30px;
  }
}

.phone-contact{
  border-left: 1px solid #ccc;
  @media screen and (max-width: @screen-xs-max) {
    border-left: 0px;
    margin-top: 1em;
  }
  &.line-left{
    border-right: 1px solid #ccc;
    border-left: 0px;
    @media screen and (max-width: @screen-xs-max) {
      border-right: 0px;
      margin-bottom: 1em;
      border-top: 0px;
    }
  }
  &.line-none{
    border-right: 0px;
    border-left: 0px;
  }
  img{
    position: relative;
    top:-6px;
  }
  strong.phone-number{
    font-size: 25px;
  }
  span{
    display:block;
    font-size: smaller;
  }
}

.web-contact{
  .web-contact-text{
    margin-top:10px;
  }
}

//
// search
// --------------------------------------------------
body#search{
  .content01{
    background-image: url('/dist/images/search/contentBg01.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size:40% auto;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content02{
    background-image: url('/dist/images/search/contentBg02.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content03{
    background-image: url('/dist/images/search/contentBg03.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content04{
    background-image: url('/dist/images/search/contentBg04.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content05{
    background-image: url('/dist/images/search/contentBg05.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  &.list{
    .subinfo{
      margin-top: -2em;
    }
    .btn-default{
      border-color: @brand-primary;
    }
  }
}

//
// entry
// --------------------------------------------------
body#entry{
  .content01{
    background-image: url('/dist/images/entry/contentBg01.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size:40% auto;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
}

//
// stress check
// --------------------------------------------------
body#stress-check{
  .content01{
    background-image: url('/dist/images/stress_check/contentBg01.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size:40% auto;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content02{
    background-image: url('/dist/images/stress_check/contentBg02.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size:40% auto;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
}

//
// contact
// --------------------------------------------------
body#contact{
  .content01{
    background-image: url('/dist/images/contact/contentBg01.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size:40% auto;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
}

//
// company
// --------------------------------------------------
body#company{
  .content01{
    background-image: url('/dist/images/company/contentBg01.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #77cafd;
  }
  .content02{
    background-image: url('/dist/images/company/contentBg02.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content03{
    background-image: url('/dist/images/company/contentBg03.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content05{
    background-image: url('/dist/images/company/contentBg04.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content06{
    background-image: url('/dist/images/company/contentBg06.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
}

//
// recruit
// --------------------------------------------------
body#recruit {
  .content01 {
    background-image: url('/dist/images/recruit/mainimage.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 40% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content02{
    background-image: url('/dist/images/recruit/img02.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    dl{
      dt{
        width :120px;
        @media screen and (max-width: @screen-sm-max) {
          width: 100%;
        }
      }
      dd{
        margin-left: 140px;
        @media screen and (max-width: @screen-sm-max) {
          margin-left: 0px;
        }
      }
    }
  }
  .content03{
    background-image: url('/dist/images/recruit/img03.jpg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 33% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
}


//
// blog
// --------------------------------------------------
body#blog {
  .first-content {
    background-image: url('/dist/images/blog/contentBg01.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content01{
    .blog-list{
      .well{
        border:1px solid @gray-lighter!important;
        background: #fff;
        padding:0px;
        border-radius: 2px;
        .images{
          display: inline-block;
          height: 200px;
          width: 100%;
          overflow: hidden;
        }
        .inner{
          padding:0px 20px 10px 20px;
        }
      }
    }
  }
  .single{
    img{
      max-width: 100%;
    }
    table {
      width: 100%;
      max-width: 100%;
      margin-bottom: @line-height-computed;
      border: 1px solid @table-border-color;
      font-size: @font-size-small;
      border-bottom:1px solid @table-border-color;
      text-align: center;
      // Cells
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            padding: @table-cell-padding;
            line-height: @line-height-base;
            vertical-align: top;
            border: 1px solid @table-border-color;
          }
        }
      }
      // Bottom align for column headings
      > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid @table-border-color;
        text-align: center;
      }
      // Remove top border from thead by default
      > caption + thead,
      > colgroup + thead,
      > thead:first-child {
        > tr:first-child {
          > th,
          > td {
            border-top: 0;
          }
        }
      }
      // Account for multiple tbody instances
      > tbody + tbody {
        border-top: 2px solid @table-border-color;
      }

      // Nesting
      table {
        background-color: @body-bg;
      }
    }
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border: 1px solid @table-border-color;
          text-align: center;
        }
      }
    }
    > thead > tr {
      > th,
      > td {
        border-bottom-width: 2px;
      }
    }
    th{
      background-color: @table-bg-active;
      font-weight: bold;
    }
    td{
      background-color: #fff;
    }
    th {
      label {
        font-size: 11px;
        font-weight: normal;
        margin-top: 2px;
      }
    }
  }
}

//
// business
// --------------------------------------------------
body#business{
  .content01{
    background-image: url('/dist/images/business/contentBg01.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size:contain;
  }
  .content02{
    background-image: url('/dist/images/business/contentBg02.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size:35% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content03{
    background-image: url('/dist/images/business/contentBg03.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size:50% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content04{
    background-image: url('/dist/images/business/contentBg04.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size:contain;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content05{
    background-image: url('/dist/images/business/contentBg05.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size:35% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content06{
    background-image: url('/dist/images/business/contentBg06.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size:contain;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content07{
    background-image: url('/dist/images/business/contentBg07.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size:30% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content08{
    background-image: url('/dist/images/business/contentBg08.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size:40% auto%;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .table-couse{
    td{
      text-align: center;
    }
  }
}

//
// contact company
// --------------------------------------------------
body#contact_company{
  .content01{
    background-image: url('/dist/images/contact_company/contentBg01.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size:contain;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
}

//
// index
// --------------------------------------------------
body#index{
  .main-image{
    min-height: 800px;
    background:url('/dist/images/index/mainImage.png') center center no-repeat;
    background-size: cover;
    .inner{
      padding-top: 200px;
      @media screen and (max-width: @screen-xs-max) {
        padding-top: 100px;
      }
      color: #fff;
      h1{
        font-size: 32px;
        @media screen and (max-width: @screen-xs-max) {
          font-size: 23px;
        }
      }
      .lead{
        font-size: 19px;
        @media screen and (max-width: @screen-xs-max) {
          font-size: 15px;
        }
      }
      .btn-custom01{
        width: 430px;
        @media screen and (max-width: @screen-xs-max) {
          width: 100%;
          span{
            display: block;
            margin-left:0px;
          }
        }
      }
      .btn-banner01{
        display: block;
        width: 430px;
        height: auto;
        border: 5px solid #fff;
        @media screen and (max-width: @screen-xs-max) {
          width: 100%;
        }
      }
    }
  }
  .content01{
    background-image: url('/dist/images/index/contentBg01.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size:contain;
  }
  .content0201{
    background-image: url('/dist/images/index/contentBg0201.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size:50%;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content0202{
    background-image: url('/dist/images/index/contentBg0202.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size:auto 60%;
    @media screen and (max-width: @screen-md-max) {
      background-image: none;
    }
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content03{
    background-image: url('/dist/images/index/contentBg03.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-md-max) {
      background-size: 55% auto;
    }
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content04{
    background-image: url('/dist/images/index/contentBg04.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-md-max) {
      background-size: 55% auto;
    }
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content05{
    background-image: url('/dist/images/index/contentBg05.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size:40% auto;
    @media screen and (max-width: @screen-sm-max) {
      background-image: none;
    }
  }
  .content06{
    .bg01{
      background-image: url('/dist/images/index/img13.png');
      background-position: right center;
      background-repeat: no-repeat;
    }
    .bg02{
      background-image: url('/dist/images/index/img14.png');
      background-position: right center;
      background-repeat: no-repeat;
    }
    .bg03{
      background-image: url('/dist/images/index/img15.png');
      background-position: right center;
      background-repeat: no-repeat;
    }
  }
}

//
// teaser
// --------------------------------------------------
#teaser{
  .main-image{
    min-height: 664px;
    background:url('/dist/images/teaser/mainImage.jpg') center center no-repeat;
    background-size: cover;
    img{
      @media screen and (max-width: @screen-xs-max) {
        max-width: 60%;
      }
    }
  }
  .main-image02{
    min-height: 664px;
    background:url('/dist/images/teaser/mainImage02.png') left center no-repeat;
    background-size: cover;

    img{
      @media screen and (max-width: @screen-xs-max) {
        max-width: 60%;
      }
    }
  }
  h1{
    line-height: 1.4em;
    @media screen and (max-width: @screen-xs-max) {
      font-size: 21px;
    }
  }
  .contact-button{
    text-align: right;
    margin-top: 240px;
    a{
      border-radius: 20px;
      border:2px solid #fff;
    }

    @media screen and (max-width: @screen-xs-max) {
      text-align: center;
      margin-top:30px;
    }

  }
  .content01{
    background-image: url('/dist/images/teaser/contentBg01.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content02{
    background-image: url('/dist/images/teaser/contentBg02.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .content03{
    background-image: url('/dist/images/teaser/contentBg03.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 40% auto;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none;
    }
  }
  .contact{
    a{
      padding-left: 4em;
      padding-right: 4em;
    }
  }
}
